/* Login  */
.login-card {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: linear-gradient(rgba(137, 137, 137, 0.5), rgba(137, 137, 137, 0.9)), transparent url(../../../img/login.jpg) center center/cover no-repeat fixed;
  background-position: center;
  padding: 30px 0;

}
/* Login end  */

.menu{
  color: white
}

.div-space-bottom{
  margin-bottom: 8px;
}

.check-align{
  margin-left: 15px !important;
}

/* sub title sytle  */
.custom-title-grid{
  color:#085394;
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
}

/* Document number style */
.document-number{
  color: #C72230;
  font-size:16px;
  font-weight: bold;
 }

.nav-bar-responsive-custom{
  margin-top: -31px;
}

.select-box-custom{
  display: flex;
  align-items: center;
}

.select-box-custom-div{
  padding-left: 2%;
}

